import React from "react";
import "./App.css";
import SvgEidSaip from "./eidAdha";
//import whiteLogo from "./assets/img/SAIPLogo-White.svg";
import whiteLogo from "./assets/img/white-logo.png";
//import pattern from "./assets/img/group-5.svg";
import pattern from "./assets/img/vision2030.svg";

import Lottie from 'lottie-react-web'
import loader from './assets/loader.json'
//import download from 'downloadjs'


function addTextToImageAndDownload(imageUrl, text, fontSize, textColor) {
    // Create a canvas element.
    var canvas = document.createElement("canvas");
    canvas.width = 6410;
    canvas.height = 6958;

    // Get the 2D context of the canvas.
    var ctx = canvas.getContext("2d");

    // Load the image.
    var image = new Image();
    image.onload = function() {
        //draw image width and height 100%
        ctx.drawImage(image, 0, 0, canvas.width, canvas.height);

        // Add the text to the canvas.
        ctx.font = fontSize;
        ctx.fillStyle = textColor;
        ctx.textAlign = "center";
        //fillText width 100%
        //get text width
        var textWidth = ctx.measureText(text).width;
        var textX = canvas.width / 2;
        console.log(textWidth)
            // Set the text alignment to the right for positioning from the right edge
        ctx.textAlign = 'center';


        // Draw the text at the calculated position
        ctx.fillText(text, textX, 5225);
        //textAnchor="middle"
        //convert text to rectangel

        // Get the data URL of the canvas.
        var dataUrl = canvas.toDataURL("image/png");

        // Create a download link.
        var a = document.createElement("a");
        a.href = dataUrl;
        a.download = text + ".png";
        a.click();
    };
    image.src = imageUrl;

    // Draw the image on the canvas.

}



class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: "",
            clickCounts: 0,
            reloaded: false
        };
        this.handleDownloadImage = this.handleDownloadImage.bind(this)
    }


    componentDidMount() {
        if (localStorage.getItem('tasks')) {
            document.getElementById("btn1").click()
        }
    }
    componentWillMount() {
        let OS;
        OS = App.getOS();
        this.setState({ OS });
    }

    valueCommonHandler = event => {
        let { state } = this;
        state.name = event.target.value;
        this.setState({...state, showError: false });

        // TODO: Change font based on text length 

        // const getFontSize = (textLength) => {
        //   const baseSize = 9
        //   if (textLength >= baseSize) {
        //     textLength = baseSize - 1
        //   }
        //   const fontSize = baseSize - textLength > 1? 1 : baseSize - textLength
        //   return `${fontSize}em`
        // }

        // const boxes = document.querySelectorAll('#theCard text')

        // boxes.forEach(box => {
        //   box.style.fontSize = getFontSize(box.textContent.length)
        // })
        // this.forceUpdate();
    };

    static getOS() {
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
            windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
            iosPlatforms = ["iPhone", "iPad", "iPod"],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
            os = "Mac OS";
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            os = "iOS";
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            os = "Windows";
        } else if (/Android/.test(userAgent)) {
            os = "Android";
        } else if (!os && /Linux/.test(platform)) {
            os = "Linux";
        }

        return os;
    }

    handleDownloadImage = async(name) => {
        console.log(name)
        if (!name) {
            name = localStorage.getItem('tasks')
        }
        document.activeElement.blur();
        if (name.trim() !== null && name.trim() !== "") {
            this.setState({ showError: false, isLoading: true, name });
            addTextToImageAndDownload("/static/media/eidfitr2025.85f31300.png", name, "240px urw", "#2C6E5Y");



            this.setState({ isLoading: false })



        } else {
            this.setState({ showError: true, isLoading: false });
        }
    };
    renderImage = () => {
        window.scrollTo(0, 50);
        return ( <
            div > {
                this.state.OS === "iOS" || this.state.OS === "Android" ? ( <
                    div className = "info-msg" >
                    <
                    i className = "far fa-lightbulb" / >
                    &
                    nbsp; <
                    React.Fragment >
                    للحفظ يتم الضغط مطولا على الصورة ثم حفظ كصورة. <
                    br / >
                    -للمستخدمين من نظام iOS قد تحتاج الى اعادة الاصدار في اول مره <
                    /React.Fragment> < /
                    div >
                ) : null
            }

            <
            img className = "imgToDownload"
            src = { this.state.daLink }
            alt = "حفظ كصورة" /
            >
            <
            button className = "add__btn add__btn-center"
            onClick = {
                () => this.setState({ daLink: null })
            } >
            تعديل الكرت <
            /button> < /
            div >
        );
    };


    render() {

        return ( <
            div className = "App" >
            <
            header id = "header"
            className = "header" >
            <
            div className = "containHead" >
            <
            img src = { pattern }
            alt = "pattern"
            className = "Group-5" / >
            <
            span className="-copy" > بطاقة معايدة < /span> <
            img src = { whiteLogo }
            alt = "logo"
            className = "Logo-White" / >
            <
            /div> < /
            header > <
            div className = "add-item__wrapper content" >
            <
            div className = "add-item" > {
                this.state.daLink ? (
                    this.renderImage()
                ) : ( <
                    React.Fragment >
                    <
                    div id = 'getMe'
                    className = "svgView" >
                    <
                    SvgEidSaip name = { this.state.name }
                    /> < /
                    div > <
                    div className = "add-item__row" >

                    <
                    div className = "add-item__title-wrapper" >
                    <
                    span className = "add-item__title" > الاسم على البطاقة < /span> < /
                    div > <
                    input className = "add-item__input"
                    type = "text"
                    value = { this.state.name }
                    maxLength = "40"
                    onChange = { e => this.valueCommonHandler(e, "name") }
                    />

                    {
                        this.state.showError ? ( <
                            p className = "error__message" > يجب ادخال الاسم اولاً < /p>
                        ) : null
                    } <
                    /div>

                    <
                    button className = "add__btn add__btn-center"
                    id = "btn1"
                    onClick = {
                        () => this.handleDownloadImage(this.state.name)
                    } >
                    اصدار <
                    /button>

                    {
                        this.state.isLoading ?
                            <
                            div className = 'loader' >
                            <
                            Lottie
                        options = {
                            {
                                animationData: loader
                            }
                        }
                        /> < /
                        div >: null
                    } <
                    /React.Fragment>
                )
            } <
            /div> < /
            div > <
            div className = "add-item__wrapper2 content2" >
            <
            div className = "add-item__title-wrapper" >
            <
            span className = "add-item__title2" > تطوير إدارة تقنية المعلومات < /span>

            <
            /div> < /
            div > <
            /div>
        );
    }
}

export default App;