import React from "react";
import Acknowledgement from "./eidfitr2025.png";

const SvgComponent = (props) => (

    <
    div style = {
        { overflowX: "scroll", direction: "rtl" }
    } >
    <
    svg

    id = "theCard"
    style = {
        { width: "44.625em", height: "48.510em" }
    } {...props } >
    <
    image height = { "100%" }
    width = { "100%" }
    style = {
        { direction: "rtl" }
    }
    xlinkHref = { Acknowledgement }
    fill = "none"
    fillRule = "evenodd" /
    >
    <
    text x = "357"
    y = "580"
    textAnchor = "middle"
    direction = "rtl"
    fontFamily = "urw"
    fontSize = "1.5em"
    fill = "#2c6e59" > { props.name } <
    /text> < /
    svg > <
    /div>
);
export default SvgComponent;